<template>
    <div class="container-fluid">
        <div class="row d-flex justify-content-center mt-5">
            <div class="col-3">
                <b-button class="btn btn-block" :to="{ path: '/product/configuration', query: { client_id: this.$route.query.client_id } }" variant="success" size="lg"><strong>Add Plan</strong></b-button>
            </div>
        </div>
        <div class="row justify-content-center mt-2">
            <div class="col-4 text-center">
                <b-button class="btn  mr-4" size="sm" :to="{ path: '/clients' }"><strong>Client Home</strong></b-button>
                <b-button class="btn btn-warning text-white font-weight-bold" size="sm" :to="{ path: '/clients', query: { edit: '1',id:this.$route.query.client_id } }">Client Details</b-button>

            </div>
        </div>
        <div class="row mt-5">
            <div class="col-3 pl-4 pr-4 mb-4" v-for="plan in plans" :key="plan._id">
                <div class="row" style="background-color: #ECEBED; height: 25px;"></div>
                <div class="row" style="background-color: #616381">
                    <div class="col-12 col-md-12 mt-1 d-flex justify-content-end">
                        <b-form-checkbox v-model="plan.Status" @change="switchStatus(plan.PlanId, plan.Status)" size="md" :value="'active'" :unchecked-value="'disabled'" button-variant="success" name="check-button" switch>
                           <b class="d-flex align-content-center text-white" :class="plan.Status === 'active' ? 'text-success': 'text-danger'">{{ plan.Status }}</b>
                        </b-form-checkbox>
                    </div>
                    <div class="col-12 col-md-12 mt-2 d-flex justify-content-center">
                        <b-form-group
                            label="Plan Type:"
                            label-for="rcityp"
                            label-cols-sm="4"
                            label-align-sm="right"
                            label-class="text-white font-weight-bold"
                        >
                            <b-form-input id="rcityp" disabled :value="capitalize(plan.PlanListing.Driven)" style="background-color: #ECEBED; border-radius: 0px; line-height: 30px; text-align: center; font-weight: bold;" type="text" placeholder="Enter Percentage" />
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-12 mt-2 d-flex justify-content-center">
                        <b-form-group
                            label="Proxy Type:"
                            label-for="proxyType"
                            label-cols-sm="4"
                            label-align-sm="right"
                            label-class="text-white font-weight-bold"
                        >
                            <b-form-input id="proxyType" disabled :value="plan.PlanListing.ProxyType" style="background-color: #ECEBED; border-radius: 0px; text-align: center; font-weight: bold;" type="text" />
                        </b-form-group>
                    </div>


                    <div class="col-12 col-md-12 mt-2 mb-4 d-flex justify-content-center">
                        <b-form-group
                            v-if="plan.PlanListing.Driven === 'click'"
                            label="Proxy Size:"
                            label-for="proxyType"
                            label-cols-sm="4"
                            label-align-sm="right"
                            label-class="text-white font-weight-bold"
                        >
                            <b-form-input id="planSize" class="form-control" disabled :value="plan.PlanListing.NumberOfClicks|convert" style="background-color: #ECEBED; border-radius: 0px; text-align: center; font-weight: bold;" type="text" />
                        </b-form-group>
                        <b-form-group
                            v-else
                            label="Proxy Size:"
                            label-for="proxyType"
                            label-cols-sm="4"
                            label-align-sm="right"
                            label-class="text-white font-weight-bold"
                        >
                            <b-form-input id="planSize" disabled :value="plan.PlanListing.Bandwidth|convertG" style="background-color: #ECEBED; border-radius: 0px; text-align: center; font-weight: bold;" type="text"/>
                        </b-form-group>
                    </div>

                </div>
                <div class="row" style="background-color: #616381">
                    <div class="col-6 d-flex justify-content-center">
                        <b-button variant="success" style="border-radius: 10px; font-weight: bold;">Upgrade Plan</b-button>
                    </div>
                    <div class="col-6 d-flex justify-content-center">
                        <b-button variant="danger" @click="deletePlan(plan.PlanId)" style="border-radius: 10px; font-weight: bold;">Delete Plan</b-button>
                    </div>
                    <div class="col-6 mb-4 mt-3 d-flex justify-content-center">
                        <b-button class="btn btn-block"  @click="loadAddons(plan.PlanId, plan._id)" style="border-radius: 10px; background-color: #000000; font-weight: bold;">
                            View Add-Ons</b-button>
                    </div>
                    <div v-if="plan.ProductType =='emailverifier'" class="col-6 mb-4 mt-3 d-flex justify-content-center">
                        <b-button class="btn btn-block"
                                  :to="{ path: '/creativeManagement', query: {plan_id: plan._id }}" style="border-radius: 10px; background-color: #000000; font-weight: bold;">
                            Configure email</b-button>
                    </div>
                    <div v-else class="col-6 mb-4 mt-3 d-flex justify-content-center">
                        <b-button class="btn btn-block" :to="{ path: '/domain/configuration', query: { plan_id: plan._id
                        } }" style="border-radius: 10px; background-color: #000000; font-weight: bold;">Configure Domains</b-button>
                    </div>
                </div>
                <div class="row" style="background-color: #616381">
                    <b-collapse :id="plan._id" >
                        <div class="col-12 mb-4 mt-3" v-for="addon in addons" :key="addon._id">
                            <b-form-checkbox
                                v-model="selAddon"
                                :id="toString(plan.id)"
                                @change.once="selectAddon(addon.PlanId)"
                                :name="addon._id"
                                :value="addon._id"
                            >
                                {{ addon|labelAddon }}
                            </b-form-checkbox>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";

export default {
    name: "PlanListing",
    data() {
        return {
            plans: [],
            plan_id: '',
            addons: '',
        }
    },
    filters: {
        convert: function(value) {
            if (value < 1000000) {
                return value / 1000 + 'K'
            } else {
                return value / 1000000 + 'M'
            }
        },
        convertG: function(value) {
            return value / 1000000000 + 'G'
        },
        labelAddon: function(value) {
            if ( value.CostPer5Domain && value.CostPerGB === null ) {
                return 'Add 5 Domains: ' + '$ ' + value.CostPer5Domain
            } else {
                return 'Add 5 Domains: ' + '$ ' + value.CostPer5Domain | 'Add 1 Gigabyte: ' + '$ ' + value.CostPerGB
            }
        }
    },
    created() {
        this.$root.preloader = false;
        this.loadPlans();
    },
    computed: {
        selAddon() {
            // for ( let i = 0; i < this.addons.length; i++ ) {
            //     for ( let e = 0; e < this.plan.length; e++ ) {
            // j
            //     }
            // }
            return '606e532f9a9d868cfc1f30e7'
        }
    },
    methods: {
        capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        loadPlans: function() {
            var request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/clients/plan/${this.$route.query.client_id}`, {params:request}).then(function(resp){
                //Store the stats
                this.plans = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        loadAddons(id, p_id) {
            console.log(id, + ' ' + p_id)
            axios.get(`${this.$root.serverUrl}/admin/clients/plan/addon/${id}`).then((resp) => {
                this.addons = (resp.data && resp.data.data) ? resp.data.data : [];
            }).then(() => {
                this.$root.$emit('bv::toggle::collapse', p_id)
            })
        },
        selectAddon(id) {
            alert(id)
        },
        switchStatus(id, status) {
            this.plan_id = id
            if ( status === 'active' ) { this.enablePlan(id) }

            if ( status === 'disabled') { this.disablePlan(id) }
        },
        deletePlan(id) {
            swal({ text:"Do you want to delete this plan?", icon:"info", buttons:['No','Yes']}).then(function(val) {
                if (!val) return;

                this.$root.preloader = true;

                axios.delete(`${this.$root.serverUrl}/admin/clients/plan/${this.$route.query.client_id}/${id}`).then(function (resp) {
                    //Process the results
                    if (resp.data && !resp.data.error) {
                        //Saved successfully, navigate to Plans List
                        location.reload()
                    } else {
                        //Handle errors
                        swal({title: "Error Removing Plan", text: resp.data.error.message, icon: "error"});
                    }
                }.bind(this))
                    .catch(function (err) {
                        this.$root.preloader = false;
                        this.errormsg = err.message || "An unexpected error occured";
                        swal({title: "Error", text: this.errormsg, icon: "error"});
                    }.bind(this))
            }.bind(this));
        },
        disablePlan(id) {
            axios.post(`${this.$root.serverUrl}/admin/clients/plan/disable/${this.$route.query.client_id}/${id}`).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    this.$bvToast.toast(resp.data.error.message, {
                        title: <strong>Warning: Plan status not modified.</strong>,
                        variant: 'danger',
                        solid: true
                    })
                } else {
                    this.$bvToast.toast(`Plan was successfully modified`, {
                        title: <strong>Success: Plan status modified.</strong>,
                        variant: 'success',
                        solid: true
                    })
                }
            }).catch((err) => {
                this.$bvToast.toast(err, {
                    title: <strong>Server Warning: Plan status not modified.</strong>,
                    variant: 'danger',
                    solid: true
                })
            })
        },
        enablePlan(id) {
            axios.post(`${this.$root.serverUrl}/admin/clients/plan/enable/${this.$route.query.client_id}/${id}`).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    this.$bvToast.toast(resp.data.error.message, {
                        title: <strong>Warning: Plan status not modified.</strong>,
                        variant: 'danger',
                        solid: true
                    })
                } else {
                    this.$bvToast.toast(`Plan was successfully modified`, {
                        title: <strong>Success: Plan status modified.</strong>,
                        variant: 'success',
                        solid: true
                    })
                }
            }).catch((err) => {
                this.$bvToast.toast(err, {
                    title: <strong>Server Warning: Plan status not modified.</strong>,
                    variant: 'danger',
                    solid: true
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
